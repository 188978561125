/* eslint-disable react/jsx-key */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import AspectImage from '@components/Shared/AspectImage/AspectImage';
import { HomeCardAspectStyles } from './HomeCardAspect.styled';
import FavoritingHeart from '@components/Shared/Favoriting/FavoritingHeart';
import { formatNumberWithCommas } from 'pubweb-smokey/dist/utils/formatters';
import { modelNameTransformer } from 'pubweb-smokey/dist/utils/modelNameTransformer';
import { Carousel } from 'react-responsive-carousel';

import ChevronRight from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-right.svg';
import ChevronLeft from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-lft.svg';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import CloseBtn from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';
import InfoCircle from 'pubweb-smokey/dist/images/svg/iconography-16x16/info-circle.svg';
import PhoneSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/phone.svg';

const DetailsWithContact = ({ p, cardLink }) => {
  const getContactInfoLink = (content) => {
    return (
      <Link href={`${cardLink}#learn-more`} className="contact-link">
        {content}
      </Link>
    );
  };

  return p.isContactPricing ? (
    <div className="card-info-area">
      {p.renderWithLink
        ? p.renderContentWithLink(p.renderDetails())
        : p.renderDetails()}
      <div className="price-contact">
        {getContactInfoLink(
          <>
            <PhoneSvg viewBox="0 0 16 16" className="phone-icon" />
            {p.priceLabel}
          </>
        )}
      </div>
    </div>
  ) : (
    // If we know there isn't a tool tip and we need to render with link. Link the entire card-info-area div.
    p.renderContentWithLink(
      <div className="card-info-area">
        {p.renderDetails()}
        {p.renderPriceInfo()}
      </div>
    )
  );
};

DetailsWithContact.propTypes = {
  p: PropTypes.shape({
    isContactPricing: PropTypes.bool,
    renderWithLink: PropTypes.bool,
    renderContentWithLink: PropTypes.func,
    renderDetails: PropTypes.func,
    renderPriceInfo: PropTypes.func,
    priceLabel: PropTypes.string,
  }),
  cardLink: PropTypes.string,
};

const HomeCardAspect = (props) => {
  /* 
        some may want to pass all params in an object 
        (even though they could just use spread op),
        so to support that and be flexible for existing code, 
        we combine props here
    */
  const {
    className,
    images,
    imgComponent,
    imageQueryString,
    imageAspectRatio,
    showPricing = true,
    showFavoritingHeart = false,
    stickers = [],
    isInStock = false,
    showStockIndicator = false,
    showSpecs = true,
    cardLink,
    cardLinkTarget,
    isFavorited,
    handleFavoriteClick,
    description = props.homeObject && props.homeObject.modelDescription
      ? props.homeObject.modelDescription
      : props.description,
    beds,
    baths,
    squareFeet = props.homeObject && props.homeObject.maxSquareFeet
      ? props.homeObject.maxSquareFeet
      : props.squareFeet,
    customPriceMarkupCallback,
    priceSort,
    useSlideshow = true,
    showArrows = true,
    autoPlay = false,
    showIndicators = false,
    showThumbs = false,
    swipeable = true,
    emulateTouch = true,
    infiniteLoop = true,
    dynamicHeight = false,
    forceExternalLink = false,
    homeObject,
    usePriceTooltip = false,
    acres,
  } = { ...props.homeObject, ...props };

  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const showTooltip = () => setVisibleTooltip(true);
  const hideTooltip = () => setVisibleTooltip(false);

  const handleVisible = () => {
    setVisibleTooltip(!visibleTooltip);
  };

  let altText =
    homeObject &&
    homeObject.modelDescription &&
    typeof homeObject.modelDescription === 'string'
      ? modelNameTransformer(homeObject.modelDescription)
      : '';

  // Note: forwardRef usage was required in order for this to work properly with Tippy component (see Tippy docs).
  //      Without this the clicks and keypress were not working properly as well as the onClickOutside.
  const PriceWithTooltip = forwardRef(function PriceWithTooltip(props, ref) {
    return (
      <div
        ref={ref}
        className="price-tooltip"
        onClick={handleVisible}
        tabIndex="0"
      >
        {priceSort && showPricing && (
          <div className="price">
            <p className="label">Before options</p>
            <p className="intro">
              ${priceSort && formatNumberWithCommas(priceSort) + 's'}
              <InfoCircle className="info-circle" alt="price disclaimer" />
            </p>
          </div>
        )}
      </div>
    );
  });

  //For homeObjectsthat are not a straight up string description ("Save your favorite homes")
  //Only if we have the property though!
  if (homeObject && typeof homeObject.altTextDescription !== 'undefined') {
    altText = homeObject.altTextDescription;
  }

  let imageQueryStringResult = imageQueryString || '?width=500';

  // Wrap content with a link as needed.
  // If a tab index value is required on the link, pass tabNdx as string (e.g. "-1", "0") for the desired tab index.
  const renderContentWithLink = (content, tabNdx = null, key = null) => {
    const safeCardLink = (cardLink || '').toLowerCase();

    if (safeCardLink) {
      if (
        safeCardLink.indexOf('http://') === 0 ||
        safeCardLink.indexOf('https://') === 0 ||
        forceExternalLink
      ) {
        return (
          <a
            onClick={props.onClick}
            key={key}
            className={className ? className : 'home-card-link'}
            target={cardLinkTarget || '_blank'}
            href={cardLink}
            tabIndex={tabNdx}
          >
            {content}
          </a>
        );
      } else {
        return (
          <Link
            key={key}
            onClick={props.onClick}
            href={cardLink}
            className={className ? className : 'home-card-link'}
            tabIndex={tabNdx}
          >
            {content}
          </Link>
        );
      }
    }
    return content;
  };

  // Separated this because of potential tooltip on price part and we can't add a link around tooltip.
  const renderDetails = () => {
    return (
      <div>
        {description && (
          <h5 className="card-heading">{modelNameTransformer(description)}</h5>
        )}

        {showSpecs ? (
          <p className="card-home-specs caption">
            {homeObject && beds && (
              <>{beds > 1 ? beds + ' beds' : beds + ' bed'}</>
            )}
            <> &bull; </>
            {baths && <>{baths > 1 ? baths + ' baths' : baths + ' bath'}</>}
            <> &bull; </>
            {squareFeet
              ? formatNumberWithCommas(squareFeet) + ' sq. ft.'
              : null}
            {acres && <> &bull; {acres + ' acres'}</>}
          </p>
        ) : null}
      </div>
    );
  };

  const isContactPricing = (() =>
    homeObject?.priceLabel === 'Contact Us For Pricing')();

  // Separated this because of potential tooltip and we can't add a link around that or it messes up the tooltip click.
  const renderPriceInfo = () => {
    return (
      <>
        {customPriceMarkupCallback && <>{customPriceMarkupCallback()}</>}

        {!isContactPricing &&
          homeObject?.isOnLand &&
          homeObject?.priceLabel && (
            <div className="price">
              <p className="label">With options</p>
              <p className="intro">
                {homeObject.priceLabel.includes(' Before Options')
                  ? homeObject.priceLabel.replace(' Before Options', '*')
                  : `${homeObject.priceLabel}*`}
              </p>
            </div>
          )}

        {!homeObject?.isOnLand && priceSort && showPricing && (
          <>
            {!usePriceTooltip ? (
              <div className="price">
                <p className="label">Before options</p>
                <p className="intro">
                  ${priceSort && formatNumberWithCommas(priceSort) + "'s*"}
                </p>
              </div>
            ) : (
              <Tippy
                interactive
                theme="clayton"
                visible={visibleTooltip}
                onClickOutside={hideTooltip}
                placement="top-end"
                maxWidth={'305px'}
                content={
                  <>
                    <CloseBtn
                      className="close-btn"
                      onClick={visibleTooltip ? hideTooltip : showTooltip}
                      alt="close tooltip"
                      tabIndex="0"
                    />
                    <p>
                      Base model starting prices do not include available
                      options or required delivery &amp; installation. Installed
                      price will be higher. See home details for pricing details
                      and home information.
                    </p>
                  </>
                }
              >
                <PriceWithTooltip handleOnClick={handleVisible} />
              </Tippy>
            )}
          </>
        )}
      </>
    );
  };

  // Card info area content. Made a function since this bit of code is used multiple places in the function above.
  const renderCardInfoArea = () => {
    // Render this card info with a link if slideshow and multiple images. Otherwise, render without the link.
    const renderWithLink = useSlideshow && images && images.length > 1;

    return (
      <>
        {renderWithLink && !usePriceTooltip ? (
          <DetailsWithContact
            p={{
              isContactPricing,
              renderWithLink,
              renderContentWithLink,
              renderDetails,
              renderPriceInfo,
              priceLabel: homeObject?.priceLabel,
            }}
            cardLink={cardLink}
          />
        ) : (
          // Otherwise, link the detail section as needed, but not the tippy.
          <div className="card-info-area">
            {renderWithLink
              ? renderContentWithLink(renderDetails())
              : renderDetails()}
            {renderPriceInfo()}
          </div>
        )}
      </>
    );
  };

  const renderCard = () => {
    const cardClasses = useSlideshow
      ? 'card-image-area card-slideshow'
      : 'card-image-area';

    return (
      <>
        <div className={cardClasses}>
          {/* to enable the slideshow you must pass in an array with more than one image
                    AND set useSlideshow to true */}
          {useSlideshow && images && images.length > 1 ? (
            <Carousel
              showArrows={showArrows}
              autoPlay={autoPlay}
              showIndicators={showIndicators}
              showThumbs={showThumbs}
              swipeable={swipeable}
              emulateTouch={emulateTouch}
              infiniteLoop={infiniteLoop}
              dynamicHeight={dynamicHeight}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    className="control-arrow control-prev"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onClickHandler();
                    }}
                    title={label}
                  >
                    <ChevronLeft />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    className="control-arrow control-next"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onClickHandler();
                    }}
                    title={label}
                  >
                    <ChevronRight />
                  </button>
                )
              }
            >
              {/* Modified to add home card links to each image. Previously, if using carousel indicators (dots),
                                clicking on the dot would open the detailed view instead of moving to a new image in the carousel.
                             */}
              {images.map((image, i) => {
                // When slider indicator "dots" are used, each image needs a link to the home.
                // These links also need a tab index of -1 or the carousel will mess up when using the tab key to navigate.
                return renderContentWithLink(
                  <AspectImage
                    key={i}
                    containerOrientation="landscape"
                    aspectRatio={imageAspectRatio}
                    behavior="fill-container"
                    src={image.reference + imageQueryStringResult}
                    imgComponent={imgComponent}
                    alt={altText}
                  />,
                  '-1',
                  i
                );
              })}
            </Carousel>
          ) : (
            <div className="card-image-single">
              {images && images[0] && images[0].reference && (
                <AspectImage
                  aspectRatio={imageAspectRatio}
                  containerOrientation="landscape"
                  behavior="fill-container"
                  src={images[0].reference + imageQueryStringResult}
                  imgComponent={imgComponent}
                  alt={altText}
                />
              )}
            </div>
          )}

          {/* In stock indicator and/or stickers. */}
          {((isInStock && showStockIndicator) ||
            (stickers && stickers.length > 0)) && (
            <div className="sticker-container">
              {/* If 'in stock' indicator selected, display it. */}
              {isInStock && showStockIndicator && (
                <div className="sticker label in-stock">
                  <div className="sticker-dot in-stock-dot">&nbsp;</div> In
                  Stock
                </div>
              )}
              {/* Display any stickers from the sticker array. */}
              {stickers &&
                stickers.length > 0 &&
                stickers.map((sticker) => {
                  return (
                    sticker?.label && (
                      <div
                        className="sticker label"
                        key={`sticker-${sticker.label}`}
                      >
                        <div
                          className="sticker-dot"
                          style={{
                            backgroundColor: sticker.dotColor || '#0075C9',
                          }}
                        >
                          &nbsp;
                        </div>{' '}
                        {sticker.label}
                      </div>
                    )
                  );
                })}
            </div>
          )}

          {showFavoritingHeart && (
            <div className="favoriting-heart-container-card">
              <FavoritingHeart
                isFavorited={isFavorited}
                onClick={handleFavoriteClick}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <HomeCardAspectStyles $useSlideshow={useSlideshow}>
      {/* If slideshow and more than one image, call render card and it will add the appropriate links as needed to each image in the card. */}
      {/* Otherwise, not a slideshow (e.g CTA), wrap the entire image in a link. */}
      {useSlideshow && images && images.length > 1 ? (
        <>
          {/* This will add links for individual slides on the card AND either link entire info area or half of it depending if tool tip used */}
          {renderCard()}
          {renderCardInfoArea()}
        </>
      ) : (
        <>
          {!usePriceTooltip ? (
            <>
              {/* No tool tip. This will place a link on the entire card including the info area. (e.g. a CTA) */}
              {renderContentWithLink(
                <>
                  {renderCard()}
                  {renderCardInfoArea()}
                </>
              )}
            </>
          ) : (
            <>
              {/* With tool tip. This will place a link on the card area AND a link half of info area */}
              {renderContentWithLink(renderCard())}
              {renderCardInfoArea()}
            </>
          )}
        </>
      )}
    </HomeCardAspectStyles>
  );
};

HomeCardAspect.propTypes = {
  homeObject: PropTypes.shape({
    numberInStock: PropTypes.number,
    modelId: PropTypes.number,
    modelNumber: PropTypes.string,
    modelDescription: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    baths: PropTypes.number,
    beds: PropTypes.number,
    squareFeet: PropTypes.number,
    priceSort: PropTypes.number,
    virtualTourReference: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    hasVirtualTour: PropTypes.bool,
    altTextDescription: PropTypes.string,
    maxSquareFeet: PropTypes.number,
    priceLabel: PropTypes.any,
    acres: PropTypes.number,
  }),
  images: PropTypes.arrayOf(PropTypes.shape({ reference: PropTypes.string })),
  imgComponent: PropTypes.object,
  imageQueryString: PropTypes.string,
  showPricing: PropTypes.bool,
  useSlideshow: PropTypes.bool,
  showFavoritingHeart: PropTypes.bool,
  isInStock: PropTypes.bool,
  showStockIndicator: PropTypes.bool,
  showSpecs: PropTypes.bool,
  cardLink: PropTypes.string,
  cardLinkTarget: PropTypes.string,
  isFavorited: PropTypes.bool,
  handleFavoriteClick: PropTypes.func,
  className: PropTypes.string,
  numberInStock: PropTypes.number,
  modelId: PropTypes.number,
  modelNumber: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  baths: PropTypes.number,
  beds: PropTypes.number,
  squareFeet: PropTypes.number,
  priceSort: PropTypes.number,
  virtualTourReference: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  hasVirtualTour: PropTypes.bool,
  altTextDescription: PropTypes.string,
  showArrows: PropTypes.bool,
  autoPlay: PropTypes.bool,
  showIndicators: PropTypes.bool,
  showThumbs: PropTypes.bool,
  swipeable: PropTypes.bool,
  emulateTouch: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  dynamicHeight: PropTypes.bool,
  customPriceMarkupCallback: PropTypes.string,
  aspectRatio: PropTypes.number,
  viewportWidth: PropTypes.number,
  usePriceTooltip: PropTypes.bool,
  onClick: PropTypes.func,
  acres: PropTypes.number,
};

export default HomeCardAspect;
